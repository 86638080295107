import {
  Card,
  Title,
  Form,
  TextInput,
  TextArea,
  Label,
  Submit,
  FormItem,
  SuccessMessage,
} from "./styles";
import { Content } from "../../shared/styles";
import React from "react";
import emailjs from "@emailjs/browser";

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullName: "",
      email: "",
      subject: "",
      message: "",
      sent: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  SERVICE_ID = "service_0p4jeo8";
  TEMPLATE_ID = "template_k76uhta";

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      !this.state.fullName ||
      !this.state.email ||
      !this.state.subject ||
      !this.state.message
    ) {
      alert("Please fill in all fields.");
      return;
    }
    emailjs.init();
    emailjs
      .send(this.SERVICE_ID, this.TEMPLATE_ID, this.state, "BP85pNmub3D7BPBUK")
      .then(
        (result) => {
          this.setState({
            fullName: "",
            email: "",
            subject: "",
            message: "",
            sent: true,
          });
        },
        (error) => {
          alert("An error occurred. Please try again.");
          console.log(error.text);
        }
      );
  };

  updateForm = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <>
        <Content>
          <Card>
            <Title>Contact Me</Title>
            <SuccessMessage hidden={!this.state.sent}>
              <strong>Message Sent</strong>
            </SuccessMessage>
            <Form
              onSubmit={this.handleSubmit}
              ref={this.form}
              autoComplete="on"
            >
              <FormItem>
                <Label>Full Name</Label>
                <TextInput
                  type="fullname"
                  placeholder="Full Name"
                  name="fullName"
                  value={this.state.fullName}
                  onChange={this.updateForm}
                />
              </FormItem>
              <FormItem>
                <Label>E-mail</Label>
                <TextInput
                  type="email"
                  placeholder="E-mail"
                  name="email"
                  value={this.state.email}
                  onChange={this.updateForm}
                />
              </FormItem>
              <FormItem>
                <Label>Subject</Label>
                <TextInput
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  value={this.state.subject}
                  onChange={this.updateForm}
                />
              </FormItem>
              <FormItem>
                <Label>Message</Label>
                <TextArea
                  value={this.state.message}
                  name="message"
                  onChange={this.updateForm}
                />
              </FormItem>
              <FormItem align={"flex-end"}>
                <Submit>Submit</Submit>
              </FormItem>
            </Form>
          </Card>
        </Content>
      </>
    );
  }
}

export default Contact;
